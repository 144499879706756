import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, untracked } from '@angular/core';
import { TOKEN_REQUIRED } from '@core/constants';
import { AuthService } from '@core/services';
import { jwtCredentials } from '@core/signals';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = untracked(() => jwtCredentials()?.accessToken);
    const isTokenRequired = request.context.get(TOKEN_REQUIRED);

    if (token && isTokenRequired) {
      const authRequest = request.clone({
        setHeaders: { Authorization: `Bearer ${token}` },
      });
      return next.handle(authRequest);
    }

    return next.handle(request);
  }
}
