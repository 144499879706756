import { NgTemplateOutlet } from '@angular/common';
import { Component, inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { Router, RouterOutlet } from '@angular/router';
import { ThemeService } from '@core/services';
import { MatListModule } from '@angular/material/list';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SafeAny } from '@core/types';
import { environment } from 'env';
import { DevToolSheetComponent } from '@dev-tools';
import { DrawerService } from '@shared/services';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    NgTemplateOutlet,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'y-project';
  themeService = inject(ThemeService);
  isProductionMode = environment.production;
  _bottomSheet = inject(MatBottomSheet);
  _router = inject(Router);

  //drawer variables
  @ViewChild('drawer') drawer!: MatSidenav;
  drawerService = inject(DrawerService);
  drawerTemplate!: TemplateRef<SafeAny> | null; // Template to render in the drawer
  drawerContext: SafeAny = {}; // Context to pass to the template

  constructor() {
    this.themeService.syncWithUserPreferedTheme();
    this._router.initialNavigation();
  }

  ngOnInit() {
    this.drawerHandler();
  }

  drawerHandler() {
    this.drawerService.drawer$.subscribe(({ template, context }) => {
      this.drawerTemplate = template;
      this.drawerContext = context || {};
      if (!template) {
        this.drawer.close();
        return;
      }
      this.drawer.open().then();
    });
  }

  openBottomSheet(): void {
    this._bottomSheet.open(DevToolSheetComponent);
  }
}
