<mat-drawer-container class="h-full" (backdropClick)="drawerService.close()">
  <mat-drawer #drawer mode="over" position="end" class="!w-1/2">
    @if (drawerTemplate) {
      <button mat-icon-button class="float-right mt-8 mr-6 !p-0 !h-6 !w-6" (click)="drawerService.close()">
        <mat-icon>close</mat-icon>
      </button>
      <ng-container>
        <ng-container *ngTemplateOutlet="drawerTemplate; context: drawerContext"></ng-container>
      </ng-container>
    }
  </mat-drawer>

  <router-outlet />
</mat-drawer-container>
@if (!isProductionMode) {
  <button mat-icon-button class="floating-button" (click)="openBottomSheet()" title="Development Tool">
    <mat-icon>settings</mat-icon>
  </button>
}
