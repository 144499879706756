import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject, Injectable, untracked } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '@core/services';
import { jwtCredentials, refreshingToken } from '@core/signals';
import { SafeAny } from '@core/types';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  router = inject(Router);
  private authService = inject(AuthService);
  private dialog = inject(MatDialog);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && !untracked(() => refreshingToken())) {
          return this.handleTokenExpired(request, next);
        }
        return throwError(() => error);
      }),
    );
  }

  private addToken(request: HttpRequest<SafeAny>, token: string): HttpRequest<SafeAny> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private handleTokenExpired(
    request: HttpRequest<SafeAny>,
    next: HttpHandler,
  ): Observable<HttpEvent<SafeAny>> {
    if (!untracked(() => refreshingToken())) {
      refreshingToken.set(true);

      return this.authService.refreshAccessToken().pipe(
        finalize(() => refreshingToken.set(false)),
        switchMap(() => {
          return next.handle(
            this.addToken(
              request,
              untracked(() => jwtCredentials()!.accessToken),
            ),
          );
        }),
        catchError((refreshError) => {
          this.authService.logout(window.location.pathname);
          this.resetAppState();
          return throwError(() => refreshError);
        }),
      );
    }
    return next.handle(request);
  }

  resetAppState() {
    this.dialog.closeAll();
  }
}
