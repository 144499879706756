import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {
  ApplicationConfig,
  importProvidersFrom,
  inject,
  provideAppInitializer,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideNativeDateAdapter } from '@angular/material/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { CoreModule } from '@core';
import { AuthService, PermissionService } from '@core/services';
import { authInitializer, permissionsInitializer } from '@core/utils';
import { AppRouteProviders } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideNativeDateAdapter(),
    AppRouteProviders,
    provideAnimationsAsync(),
    importProvidersFrom(CoreModule.forRoot()),
    AuthService,
    PermissionService,
    provideAppInitializer(() => authInitializer(inject(AuthService))),
    provideAppInitializer(() =>
      permissionsInitializer(inject(PermissionService), inject(AuthService)),
    ),
  ],
};
