import { ChangeDetectionStrategy, Component, inject, input, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';
import { LoginDto } from '@core/dtos';
import { AuthService, SplashScreenService } from '@core/services';
import { jwtCredentials } from '@core/signals';
import { CommonValidators } from '@core/validators';
import { MatErrorMessagesComponent, PasswordTogglerComponent } from '@shared/components';
import {
  PromiseButtonDirective,
  TrimInputDirective,
  ValidSubmitDirective,
} from '@shared/directives';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  imports: [
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatErrorMessagesComponent,
    MatFormFieldModule,
    MatInputModule,
    PasswordTogglerComponent,
    PromiseButtonDirective,
    ReactiveFormsModule,
    ValidSubmitDirective,
    TrimInputDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit {
  authService = inject(AuthService);
  router = inject(Router);
  loginForm: FormGroup;
  subscription!: Subscription;
  splashScreenService = inject(SplashScreenService);
  returnUrl = input();

  constructor() {
    const { required, email } = CommonValidators;
    this.loginForm = new FormGroup({
      email: new FormControl('', [required, email]),
      password: new FormControl('', [required]),
      rememberMe: new FormControl(false),
    });
    this.splashScreenService.hide();
  }

  ngOnInit(): void {
    const credentials = this.authService.restoreCredentials();
    if (credentials) this.loginForm.patchValue(credentials);
  }

  login() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.subscription = this.authService.login(this.loginForm.value as LoginDto).subscribe((_) => {
      if (jwtCredentials()?.accessToken) {
        this.router.navigate([this.returnUrl() ?? '/cpl']);
      }
    });
  }
}
