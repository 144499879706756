<div class="login-container">
  <div class="login-box">
    <div class="login-logo">
      <img src="/assets/images/logo.png" alt="Y project logo" />
    </div>
    <form [formGroup]="loginForm" class="login-form" appValidSubmit (onSubmit)="login()">
      <h5 class="Headline-5 login-form-title">Login</h5>
      <mat-form-field class="mb-s">
        <mat-label>Email Address</mat-label>
        <input appTrimInput
               matInput
               type="text"
               formControlName="email"
               required
               autofocus
               autocomplete="username"
        />
        <mat-error appMatErrorMessages></mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Password</mat-label>
        <input appTrimInput
               matInput
               type="password"
               formControlName="password"
               required
               #password
               autocomplete="current-password"
        />
        <app-password-toggler matSuffix [inputElement]="password" />
        <mat-error appMatErrorMessages></mat-error>
      </mat-form-field>

      <mat-checkbox formControlName="rememberMe" color="primary" class="mb-l"
      >Remember Password
      </mat-checkbox>
      <button mat-flat-button color="primary" class="btn-large" [appPromiseButton]="subscription">
        Login
      </button>
    </form>
  </div>
</div>
