import { CanMatchFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { environment } from 'env';

export const NonProductionGuard: CanMatchFn = () => {
  const router = inject(Router);
  if (!environment.production) {
    return true;
  } else {
    return router.createUrlTree(['/login']);
  }
};
