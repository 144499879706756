import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig } from '@angular/material/snack-bar';
import { HttpService } from '@core/services';
import { MatPaginatorIntlEn } from './adapters';
import { DEFAULT_MASK_CONFIG, MASK_CONFIG } from './configs';
import { SINGLE_TONE_MODULES } from './constants';
import { ensureModuleLoadedOnceGuard } from './guards';
import { ErrorInterceptor, JwtInterceptor, UnauthorizedInterceptor } from './interceptors';
import {
  AuthService,
  ClipboardService,
  EncryptionService,
  FileManagementService,
  PermissionService,
  QueryParamsMapperService,
  ThemeService,
} from './services';
import { authInitializer, permissionsInitializer } from './utils/auth.initializer.util';

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    ThemeService,
    HttpService,
    AuthService,
    PermissionService,
    FileManagementService,
    ClipboardService,
    QueryParamsMapperService,
    EncryptionService,
    { provide: MASK_CONFIG, useValue: DEFAULT_MASK_CONFIG },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline', subscriptSizing: 'fixed' } as MatFormFieldDefaultOptions,
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['app-snackbar', 'app-mt-100'],
      } as MatSnackBarConfig,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (authService: AuthService) => () => authInitializer(authService),
      multi: true,
      deps: [AuthService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (permissionsService: PermissionService, authService: AuthService) => () =>
        permissionsInitializer(permissionsService, authService),
      multi: true,
      deps: [PermissionService, AuthService],
    },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorIntlEn,
    },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    ensureModuleLoadedOnceGuard(parentModule, SINGLE_TONE_MODULES.CORE_MODULE);
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [],
    };
  }
}
