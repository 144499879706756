import { Component, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterOutlet } from '@angular/router';
import { ThemeService } from '@core/services';
import { SidenavComponent, SidePanelComponent } from '@shared/layout';
import { MatListModule } from '@angular/material/list';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { environment } from 'env';
import { DevToolSheetComponent } from '@dev-tools';
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    SidePanelComponent,
    SidenavComponent,
    MatButtonModule,
    MatIconModule,
    MatListModule,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'y-project';
  themeService = inject(ThemeService);
  isProductionMode = environment.production;
  _bottomSheet = inject(MatBottomSheet);
  _router = inject(Router);
  constructor() {
    this.themeService.syncWithUserPreferedTheme();
    this._router.initialNavigation();
  }
  ngOnInit(): void {}

  openBottomSheet(): void {
    this._bottomSheet.open(DevToolSheetComponent);
  }
}
